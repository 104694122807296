// redux/slices/cartSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";

// Define the structure of a PromoCode
export interface PromoCode {
  id: number;
  name: string;
  discount: number; // Parsed as a number for calculation
  discountType: "flat" | "percentage"; // Type of discount
  expiration: string; // ISO date string
  IsFreeShip: string; // Not used
}

// Define the BuyXGetYOffer interface
interface BuyXGetYOffer {
  id: number;
  X: number; // Ensure these are numbers
  Y: number; // Ensure these are numbers
  expiration: string;
  created_at: string;
  updated_at: string;
  pivot?: {
    product_id: string;
    by_x_get_y_id: string;
  };
}

// Define CartProduct and SelectedVariant interfaces
export interface CartProduct {
  id: number;
  name: string;
  price: string;
  PriceAfterDiscount?: string;
  product_image?: { image: string }[];
  buy_xget_y?: BuyXGetYOffer[];
  selectedVariant?: SelectedVariant;
  totalCart?: number;
  orderDate?: string;
  discount?: number;
  freeItems?: number;
  appliedOffer?: { X: number; Y: number };
  [key: string]: any;
}

export interface SelectedVariant {
  sku: string;
  size: string;
}

// Extend CartProduct to include discount and freeItems
export interface CartProductWithOffer extends CartProduct {
  discount: number; // Total discount from offers
  freeItems: number; // Number of free items
  originalPrice: number; // Original price before discount
  discountedPrice: number; // Price after discount
}

// Define the Cart State
interface CartState {
  cartProducts: CartProductWithOffer[];
  appliedPromoCode: PromoCode | null;
  availablePromoCodes: PromoCode[];
  loadingPromoCodes: boolean;
  totals: {
    subtotal: number; // Sum of discounted prices
    savingsFromPrices: number; // Total savings from price reductions
    promoDiscount: number; // Total promo code discounts
    finalPrice: number; // subtotal - promoDiscount
  };
}

interface AddToCartPayload {
  product: CartProduct;
  selectedVariant: SelectedVariant;
  quantity: number;
  originalPrice: number;
  discountedPrice: number;
}

const initialState: CartState = {
  cartProducts: [],
  appliedPromoCode: null,
  availablePromoCodes: [],
  loadingPromoCodes: false,
  totals: {
    subtotal: 0,
    savingsFromPrices: 0,
    promoDiscount: 0,
    finalPrice: 0,
  },
};

// Async thunk to fetch promo codes
export const fetchPromoCodes = createAsyncThunk(
  "cart/fetchPromoCodes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<PromoCode[]>(
        "https://partspluseg.com/public/api/PromoCode"
      );

      const promoCodes = response.data.map((code) => ({
        ...code,
        discount: parseFloat(code.discount.toString()),
        discountType: code.discountType || "flat",
      }));

      return promoCodes;
    } catch (error: any) {
      console.error("Error fetching promo codes:", error);
      return rejectWithValue("Failed to fetch promo codes");
    }
  }
);

// Updated applyOffers Function to handle offers across different products
const applyOffers = (state: CartState) => {
  // Reset all discounts and freeItems
  state.cartProducts.forEach((product) => {
    product.discount = 0;
    product.freeItems = 0;
  });

  // Collect all unique offers from cart products
  const offerMap: { [offerId: string]: BuyXGetYOffer } = {};

  state.cartProducts.forEach((product) => {
    if (product.buy_xget_y && product.buy_xget_y.length > 0) {
      product.buy_xget_y.forEach((offer) => {
        const offerKey = offer.id.toString(); // Use offer id as key
        if (!offerMap[offerKey]) {
          offerMap[offerKey] = offer;
        }
      });
    }
  });

  // Iterate over each offer
  Object.values(offerMap).forEach((offer) => {
    const expiration = new Date(offer.expiration);
    const today = new Date();
    if (today > expiration) return; // Skip expired offers

    // Parse X and Y as numbers
    const X = Number(offer.X);
    const Y = Number(offer.Y);

    // Get eligible products in the cart for this offer
    const eligibleProducts = state.cartProducts.filter((product) =>
      product.buy_xget_y?.some((o) => o.id === offer.id)
    );

    if (eligibleProducts.length === 0) return;

    // Total quantity across eligible products
    const totalQuantity = eligibleProducts.reduce(
      (sum, item) => sum + (item.totalCart || 0),
      0
    );

    // Calculate free items
    const offerUnit = X + Y;
    const applicableTimes = Math.floor(totalQuantity / offerUnit);
    const numFreeItems = applicableTimes * Y;

    if (numFreeItems > 0) {
      // Assign free items starting from the cheapest product
      let remainingFree = numFreeItems;

      // Sort eligible products by discounted price (ascending)
      const sortedProducts = [...eligibleProducts].sort(
        (a, b) => a.discountedPrice - b.discountedPrice
      );

      for (const item of sortedProducts) {
        if (remainingFree <= 0) break;

        const possibleFree = Math.min(item.totalCart || 0, remainingFree);
        item.freeItems = (item.freeItems || 0) + possibleFree;

        // Discount is calculated based on the discounted price
        item.discount = (item.discount || 0) + item.discountedPrice * possibleFree;

        remainingFree -= possibleFree;
      }
    }
  });
};

// Function to calculate totals
const calculateTotals = (state: CartState) => {
  let tempSubtotal = 0;
  let tempSavingsFromPrices = 0;
  let tempOfferDiscount = 0;

  state.cartProducts.forEach((product) => {
    const totalProductPrice = product.discountedPrice * (product.totalCart || 0);
    tempSubtotal += totalProductPrice;
    tempSavingsFromPrices +=
      (product.originalPrice - product.discountedPrice) * (product.totalCart || 0);
    tempOfferDiscount += product.discount || 0; // Discount from offers
  });

  let tempPromoDiscount = 0;

  // Apply promo code discount
  if (state.appliedPromoCode) {
    if (state.appliedPromoCode.discountType === "flat") {
      tempPromoDiscount = state.appliedPromoCode.discount;
    } else if (state.appliedPromoCode.discountType === "percentage") {
      tempPromoDiscount =
        ((tempSubtotal - tempOfferDiscount) * state.appliedPromoCode.discount) / 100;
    }
  }

  // Ensure that promoDiscount does not exceed subtotal minus offer discounts
  tempPromoDiscount = Math.min(
    tempPromoDiscount,
    tempSubtotal - tempOfferDiscount
  );

  const finalPrice = tempSubtotal - tempOfferDiscount - tempPromoDiscount;

  state.totals = {
    subtotal: tempSubtotal,
    savingsFromPrices: tempSavingsFromPrices,
    promoDiscount: tempOfferDiscount + tempPromoDiscount,
    finalPrice: finalPrice,
  };
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Action to add a product to the cart
    cart_product: (state, { payload }: PayloadAction<AddToCartPayload>) => {
      const { product, selectedVariant, quantity, originalPrice, discountedPrice } =
        payload;

      // Validate product
      if (!product || typeof product.id === "undefined") {
        console.error("Invalid product payload:", payload);
        toast.error("Failed to add product to cart.", { duration: 1000 });
        return;
      }

      // Validate selectedVariant
      if (!selectedVariant || !selectedVariant.sku || !selectedVariant.size) {
        console.error("Invalid selectedVariant payload:", payload);
        toast.error("Please select a valid variant.", { duration: 1000 });
        return;
      }

      // Define orderDate
      const now = new Date();
      const orderDate = now.toLocaleString();

      // Find if the product with the same variant already exists in the cart
      const existingProductIndex = state.cartProducts.findIndex(
        (item) =>
          item.id === product.id &&
          item.selectedVariant?.sku === selectedVariant.sku &&
          item.selectedVariant?.size === selectedVariant.size
      );

      if (existingProductIndex >= 0) {
        // Update quantity if product exists
        state.cartProducts[existingProductIndex].totalCart! += quantity;

        // Optionally, update prices if they might have changed
        state.cartProducts[existingProductIndex].originalPrice = originalPrice;
        state.cartProducts[existingProductIndex].discountedPrice = discountedPrice;
      } else {
        // Add new product to the cart
        const newProduct: CartProductWithOffer = {
          ...product,
          totalCart: quantity,
          selectedVariant: { ...selectedVariant },
          orderDate: orderDate,
          discount: 0, // Will be updated below
          freeItems: 0, // Will be updated below
          originalPrice: originalPrice,
          discountedPrice: discountedPrice,
        };

        state.cartProducts.push(newProduct);
        toast.success(`${product.name} added to cart`, { duration: 1000 });
      }

      // After adding/updating, apply offers and calculate totals
      applyOffers(state);
      calculateTotals(state);
    },

    // Action to remove a product from the cart
    remove_cart_product: (
      state,
      { payload }: PayloadAction<CartProductWithOffer>
    ) => {
      if (
        !payload.selectedVariant ||
        !payload.selectedVariant.sku ||
        !payload.selectedVariant.size
      ) {
        console.error("Invalid selectedVariant in payload:", payload);
        toast.error("Failed to remove product from cart.", { duration: 1000 });
        return;
      }

      // Remove the product from the cart
      state.cartProducts = state.cartProducts.filter(
        (item) =>
          item.id !== payload.id ||
          item.selectedVariant?.sku !== payload.selectedVariant.sku ||
          item.selectedVariant?.size !== payload.selectedVariant.size
      );

      // After removing, apply offers and calculate totals
      applyOffers(state);
      calculateTotals(state);

      toast.error(`Removed ${payload.name} from your cart`, { duration: 1000 });
    },

    // Action to decrease the quantity of a product in the cart
    decrease_quantity: (
      state,
      { payload }: PayloadAction<CartProductWithOffer>
    ) => {
      if (
        !payload.selectedVariant ||
        !payload.selectedVariant.sku ||
        !payload.selectedVariant.size
      ) {
        console.error("Invalid selectedVariant in payload:", payload);
        toast.error("Failed to decrease product quantity.", {
          duration: 1000,
        });
        return;
      }

      const productIndex = state.cartProducts.findIndex(
        (item) =>
          item.id === payload.id &&
          item.selectedVariant?.sku === payload.selectedVariant.sku &&
          item.selectedVariant?.size === payload.selectedVariant.size
      );

      if (productIndex >= 0) {
        const currentQuantity = state.cartProducts[productIndex].totalCart || 0;

        if (currentQuantity > 1) {
          state.cartProducts[productIndex].totalCart! -= 1;
          toast.success("Quantity decreased", { duration: 1000 });
        } else {
          toast.error("Quantity cannot be less than 1", { duration: 1000 });
        }

        // After decreasing, apply offers and calculate totals
        applyOffers(state);
        calculateTotals(state);
      }
    },

    // Action to clear the entire cart with confirmation
    clear_cart: (state) => {
      state.cartProducts = [];
      state.appliedPromoCode = null; // Remove applied promo code
      toast.success("Cart cleared", { duration: 1000 });
      calculateTotals(state);
    },

    // New action to clear cart without confirmation
    reset_cart_state: (state) => {
      state.cartProducts = [];
      state.appliedPromoCode = null; // Remove applied promo code
      calculateTotals(state);
    },

    // Action to apply a promo code
    apply_promo_code: (state, { payload }: PayloadAction<string>) => {
      if (state.loadingPromoCodes) {
        toast.error(
          "Promo codes are still loading. Please try again shortly.",
          { duration: 1000 }
        );
        return;
      }

      if (!state.availablePromoCodes || state.availablePromoCodes.length === 0) {
        toast.error("No promo codes available at the moment.", {
          duration: 1000,
        });
        return;
      }

      const code = state.availablePromoCodes.find(
        (promo) => promo.name.toLowerCase() === payload.toLowerCase()
      );

      if (!code) {
        toast.error("Invalid promo code.", { duration: 1000 });
        return;
      }

      const today = new Date();
      const expirationDate = new Date(code.expiration);
      if (today > expirationDate) {
        toast.error("Promo code has expired.", { duration: 1000 });
        return;
      }

      state.appliedPromoCode = code;
      toast.success(`Promo code "${code.name}" applied!`, { duration: 1000 });
      calculateTotals(state);
    },

    // Action to remove the applied promo code
    remove_promo_code: (state) => {
      state.appliedPromoCode = null;
      toast.info("Promo code removed.", { duration: 1000 });
      calculateTotals(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPromoCodes.pending, (state) => {
      state.loadingPromoCodes = true;
    });
    builder.addCase(fetchPromoCodes.fulfilled, (state, action) => {
      state.availablePromoCodes = action.payload;
      state.loadingPromoCodes = false;
    });
    builder.addCase(fetchPromoCodes.rejected, (state, action) => {
      state.loadingPromoCodes = false;
      toast.error("Failed to fetch promo codes.", { duration: 1000 });
    });
  },
});

export const {
  cart_product,
  remove_cart_product,
  clear_cart,
  reset_cart_state, // Exported the new action
  decrease_quantity,
  apply_promo_code,
  remove_promo_code,
} = cartSlice.actions;

export default cartSlice.reducer;

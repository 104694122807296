import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductsType } from "@/interfaces/interFace";
import { toast } from "sonner";

interface WishlistState {
  wishlistProducts: ProductsType[];
}

const initialState: WishlistState = {
  wishlistProducts: [],
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    // Add a product to the wishlist
    wishlist_product: (state, { payload }: PayloadAction<ProductsType>) => {
      // Ensure wishlistProducts is always an array
      state.wishlistProducts = state.wishlistProducts || [];

      const productIndex = state.wishlistProducts.findIndex(
        (item) => item.id === payload.id
      );

      if (productIndex >= 0) {
        toast.info("Product already in wishlist", {
          duration: 1000,
        });
      } else {
        state.wishlistProducts.push(payload);
        toast.success(`${payload.name} added to wishlist`, {
          duration: 1000,
        });
      }
    },
    // Remove a product from the wishlist
    remove_wishlist_product: (
      state,
      { payload }: PayloadAction<ProductsType>
    ) => {
      state.wishlistProducts = state.wishlistProducts.filter(
        (item) => item.id !== payload.id
      );
      toast.error(`${payload.name} removed from your wishlist`, {
        duration: 1000,
      });
    },
    // Clear all wishlist items
    clear_wishlist: (state) => {
      state.wishlistProducts = [];
      toast.success("Wishlist cleared", {
        duration: 1000,
      });
    },
  },
});

export const {
  wishlist_product,
  remove_wishlist_product,
  clear_wishlist,
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
